import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Paper } from '@material-ui/core';

import SEO from '../components/seo';

import styles from '../styles/about-us.module.scss';

const AboutUsPage = ({ data }) => (
    <>
        <SEO
            title='About Us'
            path='/about-us/'
            description='Our mission is to give jewelry lovers a place to explore new and exciting brands.'
        />
        <BackgroundImage
            Tag='section'
            className={styles.header}
            fluid={data.banner.childImageSharp.fluid}
        >
            <div className={styles.inner}>
                <h1>About <mark>Roselium</mark></h1>
            </div>
        </BackgroundImage>
        <section className={styles.aboutus}>
            <Paper className={styles.content}>
                <h5>Our mission is to give jewelry lovers a place to explore new and exciting brands.</h5>
                <blockquote>
                    <p className={styles.quote}><q>What's the name of that store I got this ring from?... I don’t remember.</q></p>
                    <p>That’s how we got the idea of <mark>Roselium</mark>.</p>
                    <p>There are so many awesome stores out there to be found. I might get lucky and come across a few at the <mark>Fairfax Trading Post</mark>, or randomly walk past one when window shopping on <mark>Melrose Avenue</mark>. But I often find myself going through thousands of (cat) photos on my phone, trying to find out whether I took a picture of that store.</p>
                    <p>Now, you might say if it’s truly unique and special, I should at least remember the name. Maybe... but what about all the other jewelry stores that I never had the opportunity to find? Or the ones I didn’t give a chance because the brand seemed “not my style” at the time?</p>
                    <p>Roselium is created to bring together all the awesome jewelry stores we could’ve missed. We introduce them through our <Link to='/monthly-jewelry-subscription/'>monthly subscription</Link>, and provide an <a href='/store/'>online jewelry marketplace</a> so you will <em>never miss a shine</em>.</p>
                    <cite><span>Alice (CEO and Co-Founder)</span></cite>
                </blockquote>
            </Paper>
        </section>
        <div className={styles.collage}>
            <Img
                className={styles.img}
                fluid={data.collage1.childImageSharp.fluid}
                alt='Collage 1'
            />
            <Img
                className={styles.img}
                fluid={data.collage2.childImageSharp.fluid}
                alt='Collage 2'
            />
            <Img
                className={styles.img}
                fluid={data.collage3.childImageSharp.fluid}
                alt='Collage 3'
            />
            <Img
                className={styles.img}
                fluid={data.collage4.childImageSharp.fluid}
                alt='Collage 4'
            />
        </div>
    </>
);

export const query = graphql`
    query {
        banner: file(relativePath: { eq: "about-us-banner.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 4096) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        collage1: file(relativePath: { eq: "about-us-collage-1.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        collage2: file(relativePath: { eq: "about-us-collage-2.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        collage3: file(relativePath: { eq: "about-us-collage-3.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        collage4: file(relativePath: { eq: "about-us-collage-4.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }`;

export default AboutUsPage;